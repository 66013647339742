export let pageTagsData = {
    tagRules: {
        '/reviews/': {
            'category': 'Commercial',
            's_cat': ['Reviews'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/local/': {
            'category': 'Commercial',
            's_cat': ['Local', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/guides/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/banking/': {
            'category': 'Commercial',
            's_cat': ['Banking', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/live-dealer/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/sports-betting/nba/worst-behaved-nba-fans/': {
            'category' : 'Non-Commercial',
            'cats' : 'Non-Commercial',
            's_cat' : ['Content Marketing'],
            'vertical' : 'Sports',
            'sub_vert' : 'Basketball',
            'tri_vert' : '',
            'country' : 'Canada',
            'language' : 'English',
        },
        '/sports-betting/nba/most-valuable-nba-teams/': {
            'category' : 'Non-Commercial',
            's_cat' : ['Content Marketing'],
            'vertical' : 'Sports',
            'sub_vert' : 'Basketball',
            'tri_vert' : '',
            'country' : 'Canada',
            'language' : 'English',
        },
        '/sports-betting/nhl/best-nhl-stadium-bars/': {
            'category' : 'Non-Commercial',
            's_cat' : ['Content Marketing'],
            'vertical' : 'Sports',
            'sub_vert' : 'Ice Hockey',
            'tri_vert' : 'NHL',
            'country' : 'Canada',
            'language' : 'English',
            'p_subcat': 'Content Marketing',
            'eq_page': 'https://www.onlinecasino.ca/sports-betting/nhl/best-nhl-stadium-bars/',
        },
        '/sports-betting/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Sports',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/fr/critiques/': {
            'category': 'Commercial',
            's_cat': ['Reviews'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
    },
    tagDefaults: {
        'category': 'NO TAG',
        's_cat': [],
        'vertical': 'Casino',
        'sub_vert': '',
        'tri_vert': '',
        'country': 'Canada',
        'language': 'French',
        't_list': '',
        'p_subcat': '',
        'eq_page': '',
    },
    pageTags: {
        '/': {
            'category': 'Commercial',
            's_cat': ['Homepage', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/about/': {
            'category': 'Non-Commercial',
            's_cat': ['About Us'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/sitemap/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/what-happened-in-vegas/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/most-delayed-airports/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/average-face': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/death-defying-celebs/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/success-on-singing-shows/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/sports-superstition/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/odds-of-surviving-saw/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/top-50-casinos/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/best-mlb-ballparks-ranked/': {
            'category': 'Non-Commercial',
            's_cat': ['Content Marketing'],
            'vertical': 'Sports',
            'sub_vert': 'Baseball',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/best-mlb-stadium-bars-us/': {
            'category': 'Non-Commercial',
            's_cat': ['Content Marketing'],
            'vertical': 'Sports',
            'sub_vert': 'Baseball',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/oscars-best-dressed/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/odds-of-success/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/worlds-black-market/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/congress-odds/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/winning-big-on-business-shows/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/monopoly-odds/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/gambling-attitudes/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/celebrity-tweets/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/are-canadians-lucky/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/board-game-blowups/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/odds-of-near-miss/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/odds-of-nfl/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/gambling-sentiments/': {
            'category': 'Non-Commercial',
            's_cat': [''],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/cn/': {
            'category': 'Commercial',
            's_cat': ['Country', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'Chinese',
        },
        '/online-gambling/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Gamblinc',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'Chinese',
        },
        '/guides/how-to-play-blackjack/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Guides'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/privacy-policy/': {
            'category': 'Non-Commercial',
            's_cat': [],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/banking/': {
            'category': 'Non-Commercial',
            's_cat': ['Banking'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/banking/cashout-guide/': {
            'category': 'Non-Commercial',
            's_cat': ['Banking', 'Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/': {
            'category': 'Non-Commercial',
            's_cat': ['Games'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/free/blackjack/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Free Games'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/free/roulette/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Free Games'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/free/slots/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Free Games'],
            'vertical': 'Casino',
            'sub_vert': 'Slots',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/fr/': {
            'category': 'Commercial',
            's_cat': ['Country', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/quebec/': {
            'category': 'Commercial',
            's_cat': ['Country', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/loto/': {
            'category': 'Commercial',
            's_cat': ['Country', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/argent-reel/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/machine-a-sous/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Slots',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/games/free/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Free Games'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/blackjack-strategy/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Guides'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/poker/strategy/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Guides'],
            'vertical': 'Poker',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/igt/': {
            'category': 'Commercial',
            's_cat': ['Software', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/microgaming/': {
            'category': 'Commercial',
            's_cat': ['Software', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/netent/': {
            'category': 'Commercial',
            's_cat': ['Software', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/slots/jackpots/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/bonuses/guide/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/fr/baccara/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/mac/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/video-poker/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/blackjack/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/nouveau/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/bonus/bienvenue/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },'/fr/craps/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/avantages/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/jeux-de-casino/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/histoire/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/securite/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/espacejeux/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/dix-meilleurs-conseils/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/legislation-des-casinos/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/faq/': {
            'category': 'Non-Commercial',
            's_cat': ['Guides'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/montreal/': {
            'category': 'Commercial',
            's_cat': ['Local', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/dollar-canadien/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/bonus/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/poker/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Poker',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/mega-ooolah/': {
            'category': 'Commercial',
            's_cat': ['Slot Review'],
            'vertical': 'Poker',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/fr/sans-telechargement/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'French',
        },
        '/no-download/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/new/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/best-payouts/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/real-money/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/bonuses/no-deposit/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/mobile/iphone/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/mobile/android/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/mac/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/mobile/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/mobile/ipad/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/blackberry/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/windows-phone/': {
            'category': 'Commercial',
            's_cat': ['Devices', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/bonuses/': {
            'category': 'Commercial',
            's_cat': ['Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/baccarat/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/slots/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Slots',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/blackjack/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/french-roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/craps/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/poker/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Poker',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/video-poker/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/american-roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/multi-wheel-roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/european-roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/no-zero-roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/pai-gow/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/pontoon/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/games/slots/mega-moolah/': {
            'category': 'Commercial',
            's_cat': ['Slot Review'],
            'vertical': 'Casino',
            'sub_vert': '',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/live-dealer/roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/live-dealer/blackjack/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/slots/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Slots',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/poker/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/roulette/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Roulette',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/slots/free/': {
            'category': 'Non-Commercial',
            's_cat': ['Games', 'Free Games'],
            'vertical': 'Casino',
            'sub_vert': 'Slots',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
        '/blackjack/': {
            'category': 'Commercial',
            's_cat': ['Games', 'Toplists'],
            'vertical': 'Casino',
            'sub_vert': 'Blackjack',
            'tri_vert': '',
            'country': 'Canada',
            'language': 'English',
        },
    }
};
