import aaConnector from '@netmanagement/aa-connector';
import {getPageTagsForPage} from './data/page';
import {pageTagsData} from './tags/pagetags';

const siteConfig = {
    // / required
    externalScript: process.env.AA_EXTERNAL_SCRIPT,
    globalVariableName: 'analyticstab',
    pageTagLocalNames: [
        'language',
        'country',
        'category',
        's_cat',
        'vertical',
        'sub_vert',
        'tri_vert',
        't_list',
        'p_subcat',
        'eq_page',
        'toplist_data_ids',
        'partner_data_ids',
        'variant',
        'state',
        'platform',
        'event',
        'site_section',
    ],
    userTagLocalNames: [
        'user_mobile',
        'user_tablet',
        'user_continent',
        'user_country',
        'user_state',
        'user_city',
    ],
    transactionIdSiteNumber: 1500,
    fullFunnelQueryParamName: 'caid',
    outboundLinkFolder: '/play/',
    timeOnSiteCustomPings: [10, 30, 45],
    linkContainersArray: [
        {
            linkContainerName: 'footer',
            linkContainerSelectors: ['footer-new'],
        },
        {
            linkContainerName: 'header',
            linkContainerSelectors: ['header'],
        },
        {
            linkContainerName: 'toplist',
            linkContainerSelectors: ['#toplist']
        },
        {
            linkContainerName: 'topNavigation',
            linkContainerSelectors: ['.nav-top']
        },
        {
            linkContainerName: 'component::sitefinder',
            linkContainerSelectors: ['.find-casino__box']
        },
        {
            linkContainerName: 'component::related-boxes',
            linkContainerSelectors: ['.related-item']
        },
        {
            linkContainerName: 'component::progressive-jackpot',
            linkContainerSelectors: ['.table-box-inner']
        },
        {
            linkContainerName: 'component::bonus-calculator',
            linkContainerSelectors: ['.calculator__box']
        }
    ],

    // / optional
    linkContainerDatasetName: 'aaTracker',
    breadcrumbsSelector: '#main > div.breadcrumb-container > div.breadcrumb-container__box > ul > li',
    debug: process.env.AA_DEBUG === 'true'
};

window.analyticstab = {
    ...pageTagsData.tagDefaults,
    ...window.analyticstab,
    ...getPageTagsForPage(pageTagsData, window.location.pathname)
};

new aaConnector(siteConfig);
